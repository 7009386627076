//  Core

.custom-confirm-dialog {
  & .MuiDialogTitle-root {
    padding-left: 0px;
    border-bottom-color: #dcdef1;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    & .MuiTypography-root {
      padding-left: 10px;
    }
  }
  & .MuiDialogContent-root {
    padding-left: 0px;
    border-bottom-color: #dcdef1;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    & .MuiTypography-root {
      padding-left: 13px;
      padding-top: 5px;
    }
  }
}
